import { Injectable } from "@angular/core";
import { RlHttpClient } from "rl-common/services/rl-http-client.service";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class AboutAlliantService {

	constructor(private readonly _httpClient: RlHttpClient) { }

	getAlliantSystemInfo(): Observable<AlliantSystemInformation> {
		return this._httpClient
			.get(`/alliant/system-environment`)
			.fetch<AlliantSystemInformation>()
			.pipe(
				catchError(() => of(null))
			);
	}

	resetMetadata(): Observable<unknown> {
		return this._httpClient
			.get(`/alliant/reset`)
			.fetch();
	}
}

export interface AlliantSystemInformation {
	isDataBridgeActive: boolean;
	serverAddress: string;
	layerDescription: string;
	apiEndpoint: string;
	currentDatabaseVersion: string;
	softwareBuildNumber: string;
	releaseName: string;
	isMetadataAvailable: boolean;
}
